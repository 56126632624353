import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Goal Setting</span>
                            <h3>The Purpose-driven Enterprise Architecture </h3>
                            
                            <p>Purpose-driven enterprise architecture is a strategic approach to aligning an organization's technology infrastructure with its business goals and objectives. It involves defining the business's vision, mission, and values, and then using this information to inform the design and implementation of technology systems. The ultimate goal of purpose-driven enterprise architecture is to create a technology infrastructure that supports the business's long-term success.</p>

                            <p>Here are a few key benefits of using a purpose-driven approach to enterprise architecture:</p>

                            <p><strong>Improved Alignment:</strong> Purpose-driven enterprise architecture helps ensure that technology systems and processes are aligned with the business's goals and objectives. This leads to improved decision-making and better outcomes, as resources are focused on initiatives that support the business's mission and vision.</p>

                            <p><strong>Increased Agility:</strong> Purpose-driven enterprise architecture is designed to be flexible and adaptable, allowing the business to quickly respond to changes in the market or customer needs. This leads to increased agility and a competitive advantage in an ever-changing business environment.</p>

                            <p><strong>Better Integration:</strong> Purpose-driven enterprise architecture helps ensure that technology systems are integrated and work together seamlessly, which leads to improved efficiency and productivity. This is particularly important for businesses that rely on multiple technology systems to support their operations.</p>
                           
                            <p><strong>Enhanced Customer Experience:</strong> Purpose-driven enterprise architecture allows businesses to design technology systems that support a positive customer experience. By aligning technology with the business's mission and values, e-commerce businesses can create a consistent and seamless customer experience across all touchpoints, including websites, mobile apps, and physical stores.</p>

                            <p><strong>Improved Decision-Making:</strong> Purpose-driven enterprise architecture provides a clear and comprehensive view of the technology landscape, which enables informed decision-making. Business leaders can use this information to make informed decisions about investments in technology and prioritize initiatives that support the business's goals and objectives.</p>

                            <p>To implement a purpose-driven approach to enterprise architecture, businesses must first define their vision, mission, and values. This information should be used to inform the design and implementation of technology systems, and should be regularly reviewed and updated to ensure continued alignment with the business's goals and objectives.</p>

                            <p>Purpose-driven enterprise architecture is a powerful tool for businesses looking to optimize their technology infrastructure and achieve long-term success. By aligning technology with the business's goals and values, businesses can create a technology infrastructure that supports their mission and vision, drives improved outcomes, and delivers a better customer experience.</p>
                            
                            {/* <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h3>E-Commerce Solutions</h3> */}

                            {/* <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div> */}

                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent